import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from '../auth';

export default class Success extends Component {
    render() {
        const profile = (auth && auth.getProfile()) || {};
        return (
            <div className={'flex-col'} style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '30vh'
            }}>
                <div>{`User "${profile['https://claims.cimpress.io/canonical_id']}" can now successfully subscribe to notification events!`}</div>
                <div>{'To view your list of subscriptions go to '}<Link to={'/dashboard'}>{'dashboard.'}</Link></div>
            </div>
        );
    }
}
