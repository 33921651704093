import { IconBin } from '@cimpress-technology/react-streamline-icons';
import {
    Button,
    CodeBlock,
    Modal,
    ReactTablev6,
    Tooltip
} from '@cimpress/react-components';
import { AuthorizationCodeGrant } from '@cimpress/simple-auth-wrapper';
import React, { useEffect, useState } from 'react';
import {
    deleteSubscription,
    getSubscriptions
} from '../../services/subscriptions.ts';
import './subscription.css';

type Columns = {
  Header: string;
  accessor?: string | any;
  Cell?: ({ original }: { original: any }) => any;
  maxWidth?: string;
  id?: string;
}[];

type resourceFilter = {
  path: string;
  value: string;
  operator: string;
};

type SubscriptionType = {
  id: string;
  created: string;
  version: number;
  alertInfo: {
    channel: string;
    details: {
      email?: string;
      httpEndpoint?: string;
    };
  };
  resourceId: string;
  subscriber: string;
  resourceType: string;
  resourceFilters: resourceFilter[];
};

const Subscription: React.FC = () => {
    const [selectedSubscription, setSelectedSubscription]
    = useState<SubscriptionType>();
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const removeSubscription = async (subscriptionId: string): Promise<void> => {
        try {
            await deleteSubscription(subscriptionId);
            setSubscriptionData(
                subscriptionData.filter(({ id }) => id !== subscriptionId)
            );
        } catch (error) {
            console.error(error);
        }
    };

    const columns: Columns = [
        { Header: 'Resource Type', accessor: 'resourceType', maxWidth: '200' },
        { Header: 'Resource Id', accessor: 'resourceId', maxWidth: '300' },
        {
            Header: 'Filter',
            accessor: 'resourceFilters',
            Cell: ({ original }) => (
                <CodeBlock code={JSON.stringify(original.resourceFilters, null, 2)} />
            )
        },
        {
            Header: 'Metadata',
            accessor: 'metadata',
            Cell: ({ original }) => (
                <CodeBlock code={JSON.stringify(original?.metadata || [], null, 2)} />
            )
        },
        {
            id: 'chanel',
            Header: 'Channel',
            accessor: (data: {
        alertInfo: { details: { email: string; httpEndpoint: string }, channel: string };
      }) => `(${data.alertInfo.channel}) ${data.alertInfo.details.email || data.alertInfo.details.httpEndpoint}`
        },
        {
            Header: 'Action',
            maxWidth: '100',
            Cell: ({ original }) => (
                <>
                    <Button
                        variant="anchor"
                        onClick={() => {
                            setSelectedSubscription(original);
                            setOpenModal(true);
                        }}
                    >
                        <Tooltip contents={'Unsubscribe'}>
                            <IconBin size={'lg'} weight={'fill'} />
                        </Tooltip>
                    </Button>
                </>
            )
        }
    ];

    const fetchSubscriptions = async (): Promise<void> => {
        try {
            setLoadingData(true);
            const { subscriptions } = await getSubscriptions();
            setSubscriptionData(subscriptions);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingData(false);
        }
    };

    const refreshUserToken = (): void => {
        const authorizationCodeGrant = new AuthorizationCodeGrant({
            clientID: process.env.REACT_APP_NOTIFICATIONS_CLIENT_ID,
            redirectRoute: '/dashboard'
        });
        authorizationCodeGrant.login({ nextUri: `${process.env.REACT_APP_BASE_URL}/dashboard` });
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    return (
        <>
            <Modal
                status={'danger'}
                show={openModal}
                onRequestHide={() => setOpenModal(false)}
                closeButton={true}
                title={'Delete Subscription'}
                footer={
                    <div className="" style={{ justifyContent: 'space-between' }}>
                        <Button onClick={() => setOpenModal(false)} style={{ marginRight: '32px' }}>Close</Button>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                removeSubscription(selectedSubscription.id);
                                setOpenModal(false);
                            }}
                        >
                            {'Delete'}
                        </Button>
                    </div>
                }
            >
                <p>
          Deleting this subscription will permanently remove it and cannot be
          retrieved.
                </p>
            </Modal>
            <div className="flex-col">
                <div className="flex-row">
                    <h1 style={{ margin: '16px 0' }}>My Subscriptions</h1>
                    <Button
                        style={{
                            maxWidth: '200px',
                            maxHeight: '48px',
                            marginTop: 'auto',
                            marginLeft: 'auto',
                            marginBottom: '16px'
                        }}
                        onClick={refreshUserToken}
                    >
            Refresh Token
                    </Button>
                </div>
                <ReactTablev6
                    minRows={(subscriptionData || []).length ? 0 : 10}
                    columns={columns}
                    data={subscriptionData}
                    loading={loadingData}
                    keyField="Resource Id"
                />
            </div>
        </>
    );
};

export default Subscription;
