import React, { Component } from 'react';
import { Spinner } from '@cimpress/react-components';

export default class Loading extends Component {
    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }
}
