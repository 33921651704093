import React, { Fragment, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';
import Shell from './components/shell.tsx';
import Success from './components/success.js';
import auth from './auth';
import PageNotFound from './components/404';
import Loading from './components/loading';
import Subscription from './components/subscriptions/subscription.tsx';
import SubscriptionAuthorize from './components/subscriptions/authorize';
import { SnackbarProvider } from './components/snackbarProvider.tsx';

function App() {
    const [authenticating, setAuthenticating] = useState(false);
    const [authenticationError, setAuthenticationError] = useState();

    useEffect(() => {
        if (!auth.isLoggedIn()) {
            setAuthenticating(true);
            auth
                .ensureAuthentication({
                    nextUri: window.location.pathname + window.location.search
                })
                .then(() => setAuthenticating(false))
                .catch(err => setAuthenticationError(err));
        }
    }, []);

    return (
        <div>
            {authenticating ? (
                <Loading />
            ) : auth.isLoggedIn() ? (
                <Fragment>
                    <SnackbarProvider>
                        <Router basename={process.env.REACT_APP_ROUTER_BASENAME || ''}>
                            <Shell>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<Navigate to="/subscriptions/authorize" />}
                                    />
                                    <Route path="/dashboard" element={<Subscription />} />
                                    <Route path="/success" element={<Success />} />
                                    <Route
                                        path="/subscriptions/authorize"
                                        element={<SubscriptionAuthorize key="only-one-authorize" />}
                                    />
                                    <Route path="*" element={<PageNotFound />} />
                                </Routes>
                            </Shell>
                        </Router>
                    </SnackbarProvider>
                </Fragment>
            ) : authenticationError ? (
                <div>Unexpected error encountered. {authenticationError.message}</div>
            ) : null}
        </div>
    );
}
export default App;
