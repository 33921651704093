import React, { createContext, useContext, useState } from 'react';
import { Snackbar } from '@cimpress/react-components';

type SnackbarContextType = {
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const SnackbarProvider = ({ children }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');

    return (
        <SnackbarContext.Provider
            value={{
                setShowSnackbar,
                setSnackbarMessage
            }}
        >
            <Snackbar
                show={showSnackbar}
                status={'success'}
                onHideSnackbar={() => setShowSnackbar(false)}
            >
                {snackbarMessage}
            </Snackbar>
            {children}
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export default SnackbarContext;
