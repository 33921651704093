import { Header } from '@cimpress-technology/react-platform-header/lib/Header';
import { AuthorizationCodeGrant } from '@cimpress/simple-auth-wrapper';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import auth from '../auth.js';
import { useSnackbar } from './snackbarProvider.tsx';
import { putSubscriptionUser } from '../services/subscriptions.ts';
import './shell.css';

const authorizationCodeGrant = new AuthorizationCodeGrant({
    clientID: process.env.REACT_APP_NOTIFICATIONS_CLIENT_ID,
    redirectRoute: '/dashboard'
});

const Shell = ({ children }) => {
    const {
        setShowSnackbar,
        setSnackbarMessage
    } = useSnackbar();
    const location = useLocation();
    const navigate = useNavigate();

    const putUserData = async () => {
        const { authorizationCode, redirectUri } = await authorizationCodeGrant.handleAuthentication({
            performRedirect: false
        });
        // this values are defined only when authorizationCodeGrant.login() has been triggered
        if ((authorizationCode || redirectUri) && location.pathname === '/dashboard') {
            // hack to sanitize the url, as it got noise from authorizationCodeGrant.login()
            navigate({ pathname: location.pathname, search: '' });
            const sub = auth.getProfile().sub;
            await putSubscriptionUser(sub, authorizationCode, redirectUri);
            setShowSnackbar(true);
            setSnackbarMessage(
                `Successfully refreshed token for ${
                    auth.getProfile()['https://claims.cimpress.io/canonical_id']
                }`
            );
        }
    };

    useEffect(() => {
        putUserData();
    }, []);

    return (
        <>
            <Header
                responsive
                accessToken={auth.getAccessToken()}
                appTitle={
                    <Link className={'navTitle'} to={'/dashboard'}>
            Subscriptions
                    </Link>
                }
                profile={auth.getProfile()}
                onLogInClicked={() => auth.login()}
                onLogOutClicked={() => auth.logout('/')}
                isLoggedIn={auth.isLoggedIn()}
            />
            <div className="root-child">{children}</div>
        </>
    );
};

export default Shell;
