import auth from '../auth';

async function checkResponse(response: Response) {
    if (response.ok) {
        if (response.status === 204) {
            return null;
        }
        return response.json();
    }
    const { status } = response;
    const { message, Message = 'something went wrong!' } = await response.json();
    return Promise.reject({ status, message: message || Message });
}

const getData = async ({ url, queryParams, headers = {} }:{url:string, queryParams?: string, headers?:any}): Promise<any> => {
    const response = await fetch(
        `${url}${queryParams ? `?${new URLSearchParams(queryParams)}` : ''}`,
        {
            headers: { ...headers, Authorization: `Bearer ${auth.getAccessToken()}` }
        }
    );
    return checkResponse(response);
};

const putData = async ({ url, putBody, headers = {} }:{url:string, putBody?: object, headers?:any}): Promise<any> => {
    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(putBody),
        headers: {
            ...headers,
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${auth.getAccessToken()}`
        }
    });
    return checkResponse(response);
};

const deleteData = async ({ url, deleteBody, headers = {} }:{url:string, deleteBody?: object, headers?:any}): Promise<any> => {
    const response = await fetch(url, {
        method: 'DELETE',
        body: JSON.stringify(deleteBody),
        headers: {
            ...headers,
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${auth.getAccessToken()}`
        }
    });
    return checkResponse(response);
};

export { getData, putData, deleteData };
