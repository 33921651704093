import React, { useEffect } from 'react';
import auth from '../../auth';
import { Spinner } from '@cimpress/react-components';
import qs from 'qs';
import isUrl from 'is-url';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../snackbarProvider.tsx';
import { AuthorizationCodeGrant } from '@cimpress/simple-auth-wrapper';
import { putSubscriptionUser } from '../../services/subscriptions.ts';

const authorizationCodeGrant = new AuthorizationCodeGrant({
    clientID: process.env.REACT_APP_NOTIFICATIONS_CLIENT_ID,
    redirectRoute: '/subscriptions/authorize'
});

const Authorize = () => {
    const {
        setShowSnackbar,
        setSnackbarMessage
    } = useSnackbar();
    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return
    const createUser = async () => {
        if (auth.isLoggedIn()) {
            const { authorizationCode, redirectUri, nextUri } = await authorizationCodeGrant.handleAuthentication({
                performRedirect: false
            });
            if ((authorizationCode || redirectUri)) {
                const sub = auth.getProfile().sub;
                await putSubscriptionUser(sub, authorizationCode, redirectUri);
                return nextUri;
            }
            await authorizationCodeGrant.login({ nextUri: window.location.href });
        }
    };

    const redirectUser = nextUri => {
        const searchParams = new URL(nextUri).search;
        const returnUri = qs.parse(searchParams, { ignoreQueryPrefix: true }).returnUri;

        if (isUrl(returnUri)) {
            window.location.href = returnUri;
        } else {
            setShowSnackbar(true);
            setSnackbarMessage(`User "${auth.getProfile()['https://claims.cimpress.io/canonical_id']}" can now successfully subscribe to notification events!`);
            navigate('/dashboard');
        }
    };

    const authorizeUser = async () => {
        const nextUri = await createUser();
        if (nextUri) {
            redirectUser(nextUri);
        }
    };

    useEffect(() => {
        authorizeUser();
    }, []);

    return (<Spinner />);
};

export default Authorize;
