import { deleteData, getData, putData } from './http.ts';

const getSubscriptions = () => {
    return getData({
        url: `${process.env.REACT_APP_SUBSCRIPTION_HUB_URL}/v1/subscriptions`
    });
};

const deleteSubscription = id => {
    return deleteData({
        url: `${process.env.REACT_APP_SUBSCRIPTION_HUB_URL}/v1/subscriptions/${id}`
    });
};

const putSubscriptionUser = (id, authorizationCode, redirectUri) => {
    return putData({
        url: `${process.env.REACT_APP_SUBSCRIPTION_HUB_URL}/v1/users/${id}?authorizationCode=${authorizationCode}&redirectUri=${redirectUri}`
    });
};

export { getSubscriptions, deleteSubscription, putSubscriptionUser };
